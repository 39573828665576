.sideBar {
  max-width: 300px;
}

.not-sideBar {
  flex: 0 0 1;
  max-width: 300px;
  padding: 10px;
  height: 100vh;
  background: #e1e1e1;
}

.sideBarItem {
  padding: 8px 24px;
  margin-top: 10px;
  cursor: move;
}

.not-sideBarItem {
  padding: 8px 16px;
  margin-top: 10px;
  background: #fff;
  border-color: #e5e7eb;
  border-style: solid;
  border-width: 1px;
  cursor: move;
}

.sideBarItem-disabled {
  cursor: not-allowed;
}

.editor {
  flex: 1 1 auto;
  margin: 20px;
}

.dropZone {
  flex: 0 0 auto;
  min-height: 40px;
  min-width: 40px;
  transition: 200ms all;
}

.dropZone.visible {
  background-color: #00a2ff;
  margin: 6px;
  opacity: 0.15;
}

.dropZone:nth-of-type(2n) {
  display: none;
}

.dropZone.horizontalDrag {
  height: auto;
}

.dropZone:not(.horizontalDrag).isLast {
  flex: 1 1 auto;
}

.groups.dropZone.horizontalDrag.isLast {
  width: 100%;
}

.dropZone.horizontalDrag.isLast {
  min-height: 32px;
}

.dropZone.active,
.trashDropZone.active {
  background: #00a2ff;
  transition: 100ms all;
  opacity: 1;
}

.component {
  height: 60px;
  padding: 10px;
  background: #aaa;
}

.column {
  border: 1px solid blue;
  flex: 1 1 100%;
  padding: 10px;
}

.columns {
  display: flex;
  padding: 20px 0;
}

.groups {
  display: flex;
  padding: 20px 0;
}

.columnContainer {
  display: flex;
}

.base {
  padding: 0.5rem 1rem;
  background-color: white;
  cursor: move;
}

.row {
  border: 1px solid red;
  background-color: rgb(25 25 25 / 2.5%);
  padding: 0;
}

.group {
  background-color: #ffffff;
  border: 1px solid #00ff00;
  padding: 0;
}

.trashDropZone {
  text-align: center;
  width: 80px;
  height: 80px;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #888;
  position: fixed;
  bottom: 20px;
  right: 40px;
  border-radius: 50%;
  font-size: 32px;
}

.editorContainer {
  display: flex;
  flex: 1;
  flex-direction: column;
  margin-bottom: 100px;
}
